import './Main.css';
import '../Common.css';
import mainLogo from'./logo5.png'
import miniLogo from'./mini-logo.png'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { 
  Box, 
  MenuItem, 
  Select, 
  TextField, 
  Collapse, 
  IconButton, 
  ToggleButton, 
  ToggleButtonGroup, 
  Slider,
} from '@mui/material';
import { 
  Replay as ReplayIcon,
  AutoAwesome as AutoAwesomeIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  LocalFlorist as LocalFloristIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import LoadingIcon from '../UiParts/LoadingIcon';
import { Loading } from 'react-loading-dot';
import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import SnsButtons from '../UiParts/SnsButtons';

const axinstance = axios.create({
  withCredentials: true
});
const config = require('../../config');
const envconf = window.location.hostname === config.DOAMIN_LOCAL 
  ? require('../../env_local') 
  : require('../../env_prod');

const RK_NONE = 0;
const RK_READ = 1;
const RK_KANJI = 2;

const SEX_NONE = 0;
const SEX_MALE = 1;
const SEX_FEMALE = 2;

const MAX_NAME_LENGTH = 3;
const MAX_READ_LENGTH = 4;

const FEEDBACK_GOOD = 0;
const FEEDBACK_BAD = 1;
const FEEDBACK_SOSO = 2;

export default function Main({}) {
  const [loading, setLoading] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const [message, setMessage] = useState("");
  const [genName, setName] = useState(null);
  const [paramList, setParamList] = useState([]);
  const [paramMaster, setParamMaster] = useState([]);
  const [paramMaster1, setParamMaster1] = useState([]);
  const [paramMaster2, setParamMaster2] = useState([]);
  const [paramMaster3, setParamMaster3] = useState([]);
  const [birthDate, setBirthDate] = useState(null);
  const [sexuality, setSexuality] = useState(0);
  const [expand, setExpand] = useState(false);
  const [readKanjiSwitch, setReadKanjiSwitch] = useState(RK_READ);
  const [length, setLength] = useState(2);
  const [strength, setStrength] = useState(3);
  const [read, setRead] = useState("");
  const [kanji, setKanji] = useState("");
  const [genCount, setGenCount] = useState(0);
  const [initTime, setInitTime] = useState(Date.now());
  const [resultId, setResultId] = useState("");
  const [started, setStarted] = useState(false);

  const initFingerprint = async () => {
    // ローカルストレージからフィンガープリントを取得
    const storedFingerprint = localStorage.getItem('fingerprint');
    
    // もしフィンガープリントが存在する場合、その値を返す
    if (storedFingerprint) {
      return storedFingerprint;
    }
  
    // フィンガープリントが存在しない場合、新たに生成して保存し、その値を返す
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const newFingerprint = result.visitorId;
    localStorage.setItem('fingerprint', newFingerprint);
    return newFingerprint;
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const fingerprint = await initFingerprint();
      const referer = document.referrer ? document.referrer : 'direct';

      axinstance
        .get(`${envconf.BACKEND_HOST}/api/v1/name/master?referer=${referer}&fp=${fingerprint}`)
        .then(response => {
          setLoading(false);
          if (response.status === 200) {
            const masterList = response.data.slice(2);
            setParamMaster(masterList);
  
            // ランダムに並び替え
            const sortedMaster = masterList.sort(() => Math.random() - 0.5);
  
            // 3分割
            const chunkSize = Math.ceil(sortedMaster.length / 3);
            setParamMaster1(sortedMaster.slice(0, chunkSize));
            setParamMaster2(sortedMaster.slice(chunkSize, chunkSize * 2));
            setParamMaster3(sortedMaster.slice(chunkSize * 2));
          }
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, []);

  const toMMDD = date => date.split('-').slice(1).join('');

  const getName = async () => {
    let additionalParam = '';
    if (birthDate) {
      additionalParam += `&birth=${toMMDD(birthDate)}`;
    }
    if (readKanjiSwitch === RK_READ && read) {
      if (!checkKana(read)) {
        alert('読みはひらがなで指定してください');
        setExpand(true);
        return;
      }
      additionalParam += `&read=${read}&length=${length}`;
    } else if (readKanjiSwitch === RK_KANJI && kanji) {
      additionalParam += `&kanji=${kanji}&length=${length}`;
    }

    const paramCodeList = paramList.map(param => param.id);

    // Referer, 滞在時間, ユーザー情報を追加
    const duration = Math.floor((Date.now() - initTime) / 1000);
    const count = genCount;
    const fingerprint = localStorage.getItem('fingerprint');

    // 使用回数を加算
    setGenCount(genCount + 1);

    setLoadingName(true);
    const baseURL = `${envconf.BACKEND_HOST}/api/v1/name`;
    const params = new URLSearchParams({
      params: JSON.stringify(paramCodeList).replace(/"/g, ''),
      sex: sexuality,
      strength,
      duration,
      fingerprint,
      count
    });

    // Manually append the additionalParam to the URL
    const url = `${baseURL}?${params.toString()}${additionalParam}`;

    try {
      const response = await axinstance.get(url);
      setLoadingName(false);
      if (response.status === 200) {
        setName(response.data);
        setResultId(response.data.result_id); // result_idを記録する
      }
    } catch (error) {
      setLoadingName(false);
    }
  };

  
  const incrementLength = () => length < MAX_NAME_LENGTH && setLength(length + 1);
  const decrementLength = () => length > 1 && setLength(length - 1);

  const addParam = id => {
    if (paramList.some(item => item.id === id) || paramList.length > 2) {
      return;
    }
    setMessage('');
    const param = paramMaster.find(item => item.id === id);
    setParamList([...paramList, param]);
  };

  const deleteParam = index => setParamList(paramList.filter((_, i) => i !== index));

  const retry = () => setName(null);

  const checkKana = targetValue => /^[ぁ-ん]+$/.test(targetValue);

  const flowerList = content_str => content_str.split('、').map((item, index) => <li key={index}>{item}</li>);

  const formattedDate = birthDate ? `${parseInt(birthDate.split('-')[1])}月${parseInt(birthDate.split('-')[2])}日` : '';

  const sendFeedback = async feedback => {
    try {
      await axinstance.get(`${envconf.BACKEND_HOST}/api/v1/name/feedback`, {
        params: {
          resultId,
          feedback
        }
      });
      retry(); // フィードバック送信に成功したらもう一度試す
    } catch (error) {
      console.error('フィードバックの送信に失敗しました', error);
    }
  };

  let contentJSX = <>
    <img  src={mainLogo} className="logo-image" alt="fireSpot"/>
    <Box className="common-horizontal-flex common-flex-center">
      <p>AI名前ジェネレータは季節やイメージから名前を生成できるツールです。<br/>読みから漢字を選んだり、お好みの漢字を盛り込んだ名前を生成することもできます。</p>
    </Box>
    <Box className='common-horizontal-flex common-flex-center common-some-padding'>
      <Box className='start-button common-horizontal-flex common-flex-center' onClick={() => setStarted(true)}>
        <PlayCircleOutlineIcon fontSize='large' />
        <div>&nbsp;スタート</div>
      </Box>
    </Box>
  </>;
  if (started) {
    contentJSX = <>
      <img  src={miniLogo} className="logo-image" alt="fireSpot"/>
      <Box className="common-some-padding">
        <h2>読み・漢字</h2>
      </Box>
      <Box className="common-some-padding">
        <ToggleButtonGroup
          color="primary"
          value={readKanjiSwitch}
          exclusive
          onChange={(e) => setReadKanjiSwitch(parseInt(e.target.value))}
        >
          <ToggleButton value={RK_READ}>読みを指定</ToggleButton>
          <ToggleButton value={RK_KANJI}>漢字を指定</ToggleButton>
          <ToggleButton value={RK_NONE}>指定しない</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box className="common-some-padding">
        {readKanjiSwitch === RK_READ && (
          <Box className='common-horizontal-flex common-flex-center'>
            <Box>読みが&nbsp;</Box>
            <TextField 
              className='readkanji-input'
              value={read}
              type='text'
              placeholder='たろう'
              inputProps={{ maxLength: MAX_READ_LENGTH }}
              maxRows={1}
              onChange={(e) => setRead(e.target.value)}
              variant="standard" 
            />
            <Box>&nbsp;の&nbsp;</Box>
            <Box className='number-wrapper common-horizontal-flex common-flex-center'>
              <Box className="number-minibutton-left common-horizontal-flex common-flex-center" onClick={decrementLength}>
                <ArrowLeftIcon />
              </Box>
              <Box className="number-display">{length}</Box>
              <Box className="number-minibutton-right common-horizontal-flex common-flex-center" onClick={incrementLength}>
                <ArrowRightIcon />
              </Box>
            </Box>
            <Box>&nbsp;文字の名前</Box>
          </Box>
        )}
        {readKanjiSwitch === RK_KANJI && (
          <Box className='common-horizontal-flex common-flex-center'>
            <TextField 
              className='kanji-input'
              value={kanji}
              type='text'
              placeholder='勇'
              inputProps={{ maxLength: 1 }}
              maxRows={1}
              onChange={(e) => setKanji(e.target.value)}
              variant="standard" 
            />
            <Box>&nbsp;を含む&nbsp;</Box>
            <Box className='number-wrapper common-horizontal-flex common-flex-center'>
              <Box className="number-minibutton-left common-horizontal-flex common-flex-center" onClick={decrementLength}>
                <ArrowLeftIcon />
              </Box>
              <Box className="number-display">{length}</Box>
              <Box className="number-minibutton-right common-horizontal-flex common-flex-center" onClick={incrementLength}>
                <ArrowRightIcon />
              </Box>
            </Box>
            <Box>&nbsp;文字の名前</Box>
          </Box>
        )}
      </Box>
      <Box className="common-some-padding">
        <h2>誕生日と性別</h2>
      </Box>
      <Box className="common-horizontal-flex common-flex-center">
        <Box className="common-some-padding">
          <TextField 
            value={birthDate}
            type='date'
            sx={{"& .MuiOutlinedInput-root": {
              color: "#2e2e2e",
              backgroundColor: '#f7f7f5',
            },}}
            onChange={(e) => setBirthDate(e.target.value)}
            variant="outlined" 
          />
        </Box>
        <Box className="common-some-padding">生まれ</Box>
        <Box className="common-some-padding">
          <Select
            variant="outlined"
            value={sexuality}
            sx={{
              backgroundColor: '#f7f7f5',
            }}            inputProps={{
              MenuProps: {
                  MenuListProps: {
                      sx: {
                          backgroundColor: 'whtie'
                      }
                  }
              }
          }}
            onChange={(e) => setSexuality(e.target.value)}
          >
            <MenuItem value={SEX_NONE}>指定なし</MenuItem>
            <MenuItem value={SEX_MALE}>男の子</MenuItem>
            <MenuItem value={SEX_FEMALE}>女の子</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box className="common-some-padding" onClick={() => setExpand(!expand)}>
        <h2>イメージ</h2>
      </Box>
      <Box className="read-kanji-wrapper" onClick={() => !expand && setExpand(!expand)}>
        {!expand && `イメージ: ${paramList.length != 0 ? paramList.map((item, index) => item.param) : "指定なし"}`}
        <Collapse in={expand} unmountOnExit>
          <Box className='common-horizontal-flex parent-container'>
            <Box className='param-container'>
              {loading && <LoadingIcon />}
              <Box>
                <Box className="param-row common-horizontal-flex">
                  {paramMaster1.map(param =>{
                    if (paramList.some(element => element.id === param.id)) {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card-selected">
                        {param.param}
                      </Box>
                    } else {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card">
                        {param.param}
                      </Box>
                    }
                  })}
                </Box>
                <Box className="param-row common-horizontal-flex">
                  {paramMaster2.map(param =>{
                    if (paramList.some(element => element.id === param.id)) {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card-selected">
                        {param.param}
                      </Box>
                    } else {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card">
                        {param.param}
                      </Box>
                    }
                  })}
                </Box>
                <Box className="param-row common-horizontal-flex">
                  {paramMaster3.map(param =>{
                    if (paramList.some(element => element.id === param.id)) {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card-selected">
                        {param.param}
                      </Box>
                    } else {
                      return <Box key={param.id} onClick={() => addParam(param.id)} className="param-card">
                        {param.param}
                      </Box>
                    }
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="common-some-padding param-list-wrapper">
            {paramList.map((item, index) => (
              <Box className="common-horizontal-flex common-flex-center select-param-card" key={item.id}>
                {index + 1}:&nbsp;
                <Box>{item.param}</Box>
                <Box className="common-flex-padding"></Box>
                <IconButton onClick={() => deleteParam(index)}>
                  <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
            ))}
            {message}
          </Box>
          <Box className="common-some-padding param-list-wrapper">
          <Box className="common-horizontal-flex common-flex-center">イメージの反映度</Box>
            <Box className="common-horizontal-flex common-flex-center common-some-margin">
              <Box className="common-some-padding">弱</Box>
              <Slider
                value={strength}
                onChange={(e) => setStrength(e.target.value)}
                step={1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="auto"
              />
              <Box className="common-some-padding">強</Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <br /><br />
      {loadingName ? <LoadingIcon size={72} message={"AIで名前を生成中"} /> : (
        genName ? (
          <Box className="common-flex-padding">
            <section className="section section--border">
              <Box className="common-vertical-flex common-flex-center">
                <span className="name-caption">{genName.name}</span>
                <span className="name-read">{genName.read}</span>
              </Box>
              <p className="section__text">{genName.comment}</p>
              {genName.note && <Box className="genname-note">{genName.note}</Box>}
              {genName.flower && (
                <Box className="common-vertical-flex common-flex-center">
                  <Box className="common-horizontal-flex common-flex-center birthflower-caption">
                    <LocalFloristIcon sx={{ color: '#ddaa11' }} /> {formattedDate}の誕生花
                  </Box>
                  <Box className="birthflower-content">
                    <ul>{flowerList(genName.flower)}</ul>
                  </Box>
                </Box>
              )}
            </section>
            <br />
            <Box className='common-horizontal-flex common-flex-center common-some-padding'>
              <Box className='retry-button common-horizontal-flex common-flex-center' onClick={retry}>
                <ReplayIcon fontSize='large' />
                <div>&nbsp;もう一度ためす</div>
              </Box>
            </Box>
            <br />
            <Box className='common-horizontal-flex common-flex-center common-some-padding'>
                フィードバックを送信
            </Box>
            <Box className='common-horizontal-flex common-flex-center common-some-padding'>
              <Box className='feedback-buttons'>
                <button className='feedback-bad' onClick={() => sendFeedback(FEEDBACK_BAD)}><ThumbDownOffAltIcon /></button>
                &nbsp;&nbsp;
                <button className='feedback-good' onClick={() => sendFeedback(FEEDBACK_GOOD)}><ThumbUpOffAltIcon /></button>
              </Box>
            </Box>
            <SnsButtons/>
          </Box>
        ) : (
          <Box className='common-horizontal-flex common-flex-center'>
            <Box className='generate-button common-horizontal-flex common-flex-center' onClick={getName}>
              <AutoAwesomeIcon fontSize='large' />
              <div>&nbsp;名前をAI生成</div>
            </Box>
          </Box>
        )
      )}
    </>
  }

  return (
    <Box className="common-page-main">
      {contentJSX}
    </Box>
  );
}
