import './PageNotFound.css';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import React from 'react';
const config = require('../../config')


export default function PageNotFound({topPageUrl}) {
  return (<Box className="time-main" sx={{ width: '100%' }}>
    ページが見つかりません<br/><br/>
    <a href={topPageUrl}>トップページ</a>
  </Box>);
}

PageNotFound.propTypes = {
  topPageUrl: PropTypes.string,
}