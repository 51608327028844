import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@material-ui/core';

import './UiParts.css'

export default function LoadingIcon({size, message}) {
  return (
    <Box className="loading-conatiner">
      <Box className="loading-inner-container">
        <CircularProgress style={{'color': 'grey'}} size={size} />
      </Box>
      <Box className="loading-inner-container">
        <Box className="common-some-padding">
          {message}
        </Box>
      </Box>
    </Box>
  );
}
