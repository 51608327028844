import React from 'react';
import './App.css';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useState, useEffect } from 'react';

import axios from "axios";

import PageNotFound from '../PageNotFound/PageNotFound';
import Box from '@mui/material/Box';
import Main from '../Main/Main';
import Data from '../Data/Data';

function App() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const scrollTop = () => {
    window.scrollTo(0, 0);
  }

  let hostname = window.location.hostname;

  let routeJSX = (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/data" element={<Data />} />
      <Route path="*" element={<PageNotFound topPageUrl="/" />} />
    </Routes>
  );

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: "#383535ff",
        light: "#383535ff",
        dark: "#383535ff",
        contrastText: '#fff',
      }
    },
  })

  return (
    <ThemeProvider theme={theme}>
    <div className="app-main">
      <main>
        <Box className="main-content">
          {routeJSX}
        </Box>
      </main>
    </div>
    </ThemeProvider>
  )
}

export default App;
