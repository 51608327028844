import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';

import facebookLogo from'./2021_Facebook_icon.svg';
import twitterLogo from'./cdnlogo.com_twitter-icon.svg';
import lineLogo from'./LINE_Brand_icon.png';

import './UiParts.css'
import axios from "axios";
const axinstance = axios.create({
  withCredentials: true
})

export default function SnsButtons() {
    useEffect(() => {
        // Twitterのスクリプトを動的にロード
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);
    
        // コンポーネントのアンマウント時にスクリプトを削除することで、リソースをクリーンアップ
        return () => {
          document.body.removeChild(script);
        };
    }, []);
  return (
    <Box>
    <aside className="sns-buttons common-horizontal-flex">
      <a 
        className="sns_button fb_button common-horizontal-flex common-flex-align" 
        target="_blank" 
        title="いいね" 
        href="http://www.facebook.com/sharer.php?u=http%3A%2F%2Fai-naming.com">
          <img src={facebookLogo} className="sns-image" />
      </a>
      &nbsp;&nbsp;
      <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-url="https://ai-naming.com" data-related="" data-show-count="false">Tweet</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      &nbsp;&nbsp;
      <a 
        className="sns_button line_button" 
        href="#line_block" 
        title="LINEで送る" 
        data-line="http://line.naver.jp/R/msg/text/?%E6%8E%A8%E3%81%97%E3%83%A9%E3%83%B4http%3A%2F%2Fai-naming.com">
          <img src={lineLogo} className="sns-image" />
      </a>
    </aside>
    </Box>
  );
}
