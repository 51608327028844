import './Data.css';
import '../Common.css';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { 
  Box, 
  TextField,
  Slider,
} from '@mui/material';
import axios from "axios";
const searchParams = new URLSearchParams(window.location.search);
let defaultIndex = parseInt(searchParams.get('index')); // true


const axinstance = axios.create({
  withCredentials: true
});
const config = require('../../config');
const envconf = window.location.hostname === config.DOAMIN_LOCAL 
  ? require('../../env_local') 
  : require('../../env_prod');

  export default function Data({}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [index, setIndex] = useState(defaultIndex);
    useEffect(() => {
      setLoading(true);
      axinstance
        .get(`${envconf.BACKEND_HOST}/api/v1/name/getkanji`)
        .then(response => {
          setData(response.data)
          setLoading(false);
        })
        .catch(() => {
        });
    }, []);

    const saveAndMove = (eowindex) => {
      setLoading(true);
        let postdata = data[index];
        axinstance
        .post(`${envconf.BACKEND_HOST}/api/v1/name/setkanji`, postdata)
        .then(response => {
          setLoading(false);
          setIndex(eowindex)
        })
        .catch(() => {
        });
    }

    const flushData = () => {
      axinstance
        .get(`${envconf.BACKEND_HOST}/api/v1/name/flush`)
        .then(response => {
          alert('OK');
        })
        .catch(() => {
        });
    }

    const moveAll = () => {
      setData(data.map((item, i) => 
        i == index 
          ? { ...item, rare_read: [...item.rare_read, ...item.read], read: [] } 
          : item
      ));
    }
    const moveRead = (readIndex) => {
      setData(data.map((item, i) => {
        if (i == index) {
          const updatedRead = item.read.filter((_, i) => i !== readIndex);
          return {
            ...item,
            single_read: [...item.single_read, item.read[readIndex]],
            read: updatedRead
          };
        }
        return item;
      }));
    };
    
    const moveSingleRead = (readIndex) => {
      setData(data.map((item, i) => {
        if (i == index) {
          const updatedSingleRead = item.single_read.filter((_, i) => i !== readIndex);
          return {
            ...item,
            rare_read: [...item.rare_read, item.single_read[readIndex]],
            single_read: updatedSingleRead
          };
        }
        return item;
      }));
    };
    
    const moveRareRead = (readIndex) => {
      setData(data.map((item, i) => {
        if (i == index) {
          const updatedRareRead = item.rare_read.filter((_, i) => i !== readIndex);
          return {
            ...item,
            read: [...item.read, item.rare_read[readIndex]],
            rare_read: updatedRareRead
          };
        }
        return item;
      }));
    };
    if(typeof data[index] === 'undefined') {
      return <>ロード中</>
    }


    return (
      <Box className="common-page-main">
        <Box className="common-horizontal-flex common-flex-center common-some-margin">
        <button onClick={() => setIndex(parseInt(index)-1)}>前へ</button>
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <button onClick={() => setIndex(parseInt(index)+1)}>次へ</button>
        </Box>
        <Box className="common-horizontal-flex common-flex-center  captioon">
        {data[index].kanji}
        </Box>
        <button onClick={() => moveAll()}>MOVE ALL</button>
        <br/>
        <br/>
        <Box className="common-horizontal-flex common-flex-center">
          読み
          {data[index].read.map((read, index) =>{
            return <Box className="read-card" onClick={() => moveRead(index)}>{read}</Box>
          })}
        </Box>
        <br/>
        <hr/>
        <br/>
        <Box className="common-horizontal-flex common-flex-center">
          1文字読み
          {data[index].single_read.map((read, index) =>{
            return <Box className="read-card" onClick={() => moveSingleRead(index)}>{read}</Box>
          })}
        </Box>
        <br/>
        <hr/>
        <br/>
        <Box className="common-horizontal-flex common-flex-center">
          あまり使わない読み
          {data[index].rare_read.map((read, index) =>{
            return <Box className="read-card" onClick={() => moveRareRead(index)}>{read}</Box>
          })}
        </Box>
        <br/>
        <br/>
        <br/>
        <Box className="common-horizontal-flex common-flex-center common-some-margin">
          <Box className="common-some-padding"> 女</Box>
          <Slider
            value={data[index].male}
            onChange={(e) => setData(data.map((item, i) => 
              i == index 
                ? { ...item, male: e.target.value } 
                : item
            ))}
            step={0.1}
            marks
            min={0.0}
            max={1.0}
            valueLabelDisplay="auto"
          />
          <Box className="common-some-padding">男</Box>
        </Box>
        <Box className="common-horizontal-flex common-flex-center common-some-margin">
          <Box className="common-some-padding">最後</Box>
          <Slider
            value={data[index].first}
            onChange={(e) => setData(data.map((item, i) => 
              i == index 
                ? { ...item, first: e.target.value } 
                : item
            ))}
            step={0.1}
            marks
            min={0.0}
            max={1.0}
            valueLabelDisplay="auto"
          />
          <Box className="common-some-padding">最初</Box>
        </Box>
        <br/>
        <br/>
        <Box className="common-horizontal-flex common-flex-center common-some-margin">
        <button onClick={() => saveAndMove(index-1)}>(保存)前へ</button>
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <button onClick={() => saveAndMove(index+1)}>(保存)次へ</button>
        </Box>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <button onClick={() => flushData()}>保存</button>
        </Box>
    );
}
